<template>
  <div class="site">
    <div class="header">
      <div class="l">
        <img src="../assets/logo.png" alt="" />
        <span>{{ name }}</span>
      </div>
      <div class="r">
        <div class="nav">首页</div>
        <div class="nav">优势</div>
        <div class="nav">介绍</div>
        <div class="nav">注册</div>
        <!--        <div class="nav">晋升</div>-->
        <div class="nav">联系我们</div>
      </div>
    </div>
    <div class="banner">
      <div class="info">
        <div class="name">{{ type }}</div>
        <div class="other">{{ desc }}</div>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="title">预约咨询</div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        margin: auto;
        margin-top: 50px;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
        "
      >
        姓名：
        <div
          style="
            width: 400px;
            height: 60px;
            background-color: #f7f7f7;
            border-radius: 10px;
            font-size: 22px;
          "
        >
          <input
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 400px;
              height: 60px;
              padding: 0 12px;
              background: none;
              outline: none;
              border: 0px;
              font-size: 22px;
            "
            type="text"
            placeholder="请输入你的称呼"
          />
        </div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
        "
      >
        电话：
        <div
          style="
            width: 400px;
            height: 60px;
            background-color: #f7f7f7;
            border-radius: 10px;
            font-size: 22px;
          "
        >
          <input
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              width: 400px;
              height: 60px;
              padding: 0 12px;
              background: none;
              outline: none;
              border: 0px;
              font-size: 22px;
            "
            type="text"
            placeholder="请输入你的联系方式"
          />
        </div>
      </div>
    </div>
    <div class="tj-btn" @click="tijiao">提交</div>

    <div class="title">加入{{ name }}--我们的优势</div>
    <div class="advantage">
      <div class="box">
        <img src="../assets/ad1.png" alt="" class="bg" />
        <div class="t">顶级联创团队</div>
        <div class="b">
          {{
            company
          }}是一家年轻有活力的互联网创新创业公司。我们致力于为年轻消费群体提供从消费、工作等多维度服务。{{
            company
          }}核心股东来自互联网、计算机软件等多个领域，有一定的资源整合能力并具有多次成功创业经验。
        </div>
      </div>
      <div class="box">
        <img src="../assets/ad2.png" alt="" class="bg" />
        <div class="t">新人培训扶持</div>
        <div class="b">
          系统、专业的培训体系。{{ name }}
          联合创始人手把手教你引流、如何谈大客户，全力扶持你升级为合伙人。地推、朋友圈裂变、竞价广告、软文、自媒体短视频引流....只要你肯学，我们全都教给你！
        </div>
      </div>
      <div class="box">
        <img src="../assets/ad3.png" alt="" class="bg" />
        <div class="t">营销资源对接</div>
        <div class="b">
          {{ name }}
          团队旗下营销平台整合了包含新浪、凤凰、搜狐等20000余网络媒体、自媒体、短视频媒体和EDM资源。一手营销资源，成本比别人低了10倍！赢在起点，赶紧加入风云团队！
        </div>
      </div>
    </div>
    <div class="title">10亿网民都能用的{{ type }}平台</div>
    <div class="snip">
      <img src="../assets/pg1.jpg" alt="" class="pg" />
      <img src="../assets/pg2.jpg" alt="" class="pg" />
      <img src="../assets/pg3.jpg" alt="" class="pg" />
    </div>
    <p class="snipinfo">
      {{ name }}
      是顺应互联网经济发展和分享经济的模式创新而出现的“消费+分享”互利共赢的双惠模式下的典型代表。
      <!-- 好挡挡
      保养提醒：根据车辆的使用情况和维护周期，提供保养提醒服务。这包括定期更换机油、滤清器、轮胎轮毂的保养建议。维修记录：记录和管理车辆的维修历史，包括维修项目、维修日期、费用等信息，帮助用户跟踪车辆的维护和修理历史。 -->
    </p>
    <div class="download">
      <img src="../assets/download.png" alt="" />
    </div>
    <div class="register">
      <div class="title">注册流程</div>
      <div class="steps">
        <div class="step">
          <div class="t">第一步</div>
          <div class="b">手机扫一扫二维码，下载{{ name }}APP。</div>
        </div>
        <div class="step">
          <div class="t">第二步</div>
          <div class="b">安装{{ name }}，输入邀请码：88888，完成安装。</div>
        </div>
        <div class="step">
          <div class="t">第三步</div>
          <div class="b">
            添加导师微信：{{ wx }}，进群学习、并免费提供培训资料！
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="t">
        <div class="l">
          <div class="app">
            <!-- <img src="../assets/logo.jpg" alt="" /> -->
            <span>{{ name }}</span>
          </div>
          <div class="tel">{{ tel }}</div>
          <div class="time">客服时间：周一至周五 09：00-18：00</div>
        </div>
        <!--        <div class="r">-->
        <!--          <img src="../assets/wx.jpeg" alt="" class="code">-->
        <!--          <div class="scan">扫一扫添加客服微信</div>-->
        <!--        </div>-->
      </div>
      <div class="b">
        Copyright © 2021-2024{{ company }} 版权所有
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >{{ code }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "官网",
  props: {
    msg: String,
  },
  data() {
    return {
      name: "怡点有约",
      company: "潍坊万物信息科技有限公司",
      type: "潍坊万物信息科技有限公司",
      desc: "是一个技术咨询服务平台",
      tel: "18563609478",
      code: "鲁ICP备2024117087号-1",
      wx: "18563609478",
    };
  },
  methods: {
    tijiao() {
      alert("提交成功");
    },
  },
};
</script>

<style scoped lang="scss">
.site {
  .footer {
    background: #333;
    padding: 50px 0 100px;
    .b {
      margin-top: 50px;
      color: #9ca9d2;
      font-size: 16px;
      text-align: center;
    }
    .t {
      margin: 0 auto;
      width: 1280px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .l {
        display: flex;
        flex-direction: column;
        align-items: center;
        .app {
          display: flex;
          align-items: center;
          img {
            width: 88px;
            height: 88px;
            margin-right: 20px;
          }
          span {
            font-size: 18px;
            color: #fff;
          }
        }
        .tel {
          font-size: 36px;
          color: #fff;
          margin: 20px 0;
        }
        .time {
          font-size: 18px;
          color: #fff;
        }
      }
      .r {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 128px;
          height: 128px;
        }
        .scan {
          font-size: 12px;
          color: #fff;
          margin-top: 20px;
        }
      }
    }
  }
  .register {
    height: 700px;
    background: url("../assets/register.png") no-repeat center;
    background-size: cover;
    .title {
      color: #fff;
    }
    .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        .t {
          background: #f91c1f;
          width: 100px;
          height: 100px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: #fff;
          position: relative;
          z-index: 10;
        }
        .b {
          margin-top: -50px;
          width: 320px;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
        }
      }
    }
  }
  .download {
    text-align: center;
    padding: 40px;
    img {
      width: 267px;
    }
  }
  .snipinfo {
    font-size: 14px;
    color: #333;
  }
  .snip {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .pg {
      width: 200px;
      margin: 0 20px;
    }
  }
  .advantage {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    .box {
      width: 386px;
      height: 404px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 10px;
      .t {
        font-size: 20px;
        color: #fff;
        position: relative;
        z-index: 10;
      }
      .b {
        font-size: 14px;
        color: #fff;
        position: relative;
        z-index: 10;
        padding: 0 40px;
      }
      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .title {
    font-size: 36px;
    color: #333;
    position: relative;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .title:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 200px;
    transform: translate(-100px, 0);
    height: 4px;
    background: #fe8830;
  }
  .banner {
    height: 510px;
    background: url("../assets/banner.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .info {
      width: 510px;
      margin-right: 200px;
      .name {
        width: 510px;
        height: 94px;
        background: url("../assets/Shape.png") no-repeat center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 30px;
      }
      .other {
        font-size: 24px;
        color: #fff;
      }
    }
  }
  .header {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    .l {
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 60px;
        margin-right: 20px;
      }
      span {
        color: #fe8830;
        font-size: 24px;
      }
    }
    .r {
      display: flex;
      align-items: center;
      .nav {
        font-size: 14px;
        color: #333;
        margin: 0 30px;
      }
    }
  }
}
a {
  text-decoration: none;
  color: inherit;
}
.tj-btn {
  width: 522px;
  height: 68px;
  background: linear-gradient(180deg, #f2d8b8 0%, #ebbe85 100%);
  border-radius: 49px;
  font-weight: bold;
  font-size: 26px;
  color: #252531;
  margin: auto;
  display: flex;
  justify-content: center;
  line-height: 68px;
  align-content: center;
  margin-top: 60px;
}
</style>
